<template>
  <v-container class="page">
    <div class="page-header pb-2">
      <v-btn @click="close()" icon color="blue darken-2">
        <v-icon>mdi-close</v-icon>
      </v-btn>
      <v-spacer></v-spacer>
      <v-checkbox
        dense
        small
        hide-details
        v-model="autoplay"
        color="blue darken-2"
        class="mt-0 pt-0"
        @click="toggleAutoplay"
      ></v-checkbox>
      <div class="text-caption">Autoplay</div>
    </div>

    <div class="page-content">
      Chapter 1
      <div class="mt-2 text-h4">Hamochi’s Daily Routine</div>
      <div class="mt-2">Hamochi 的一天</div>
      <div class="mt-8">
        <v-icon size="30" color="black">mdi-star-outline</v-icon>
        <v-icon size="30" color="black">mdi-star-outline</v-icon>
        <v-icon size="30" color="black">mdi-star-outline</v-icon>
      </div>
    </div>
    <div class="page-footer">
      <div class="buttons">
        <v-btn @click="goPrevious()" icon class="mx-4" color="blue darken-2">
          <v-icon x-large>mdi-menu-left</v-icon>
        </v-btn>

        <v-btn @click="goNext()" icon class="mx-4" color="blue darken-2">
          <v-icon x-large>mdi-menu-right</v-icon>
        </v-btn>
      </div>
      <div class="mt-2 text-caption">page 2 of 100</div>
    </div>
  </v-container>
</template>

<script>
import { mapState } from "vuex";
export default {
  components: {
    //
  },
  computed: mapState({
    //
  }),
  props: [
    //
  ],
  data: () => ({
    autoplay: false,
  }),
  created() {
    //
  },
  mounted() {
    this.autoplay =
      this.$route.query.autoplay && this.$route.query.autoplay == 1
        ? true
        : false;
  },
  beforeDestroy() {
    //
  },
  methods: {
    toggleAutoplay() {
      this.$router.replace({
        query: {
          autoplay: this.autoplay ? 1 : 0,
          lang: this.$route.query.lang ? this.$route.query.lang : 1,
        },
      });
    },
    goPrevious() {
      this.$router.push({
        name: "ABCPage3",
        query: { autoplay: this.autoplay ? 1 : 0 },
      });
    },
    goNext() {
      this.$router.push({
        name: "ABCPage5",
        query: {
          autoplay: this.autoplay ? 1 : 0,
          lang: this.$route.query.lang ? this.$route.query.lang : 1,
          transition: "slide-left",
        },
      });
    },
    close() {
      this.$router.push({
        name: "ABCPage2",
      });
    },
  },
};
</script>

<style scoped>
.page-content,
.page-footer {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.page-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.page {
  height: 100% !important;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  text-align: center;
  border: #000 solid 1px;
  background: rgb(255, 255, 255) !important;
}

.buttons {
  display: flex;
}

.btn-dis {
  opacity: 0.5;
}

.vocab {
  text-decoration: underline;
  font-weight: 900;
}
</style>